const commonUrl = {
  title: "脑岛论坛",
  host: "localhost",
};
const testUrl = {
  ndUrl: "https://test.naodao.com/",
  winURL: "https://tforum.naodao.com", // 测试
  baseURL: "https://tforum.naodao.com/forum/", // 测试
  imgUrl: "https://insula-test.oss-cn-chengdu.aliyuncs.com/", // 测试
  pubUrl: "https://forum-pub-test.oss-cn-chengdu.aliyuncs.com/", // 测试
};

// 预发布
const preReleaseUrl = {
  ndUrl: "https://uat.naodao.com/",
  baseURL: "https://uforum.naodao.com/forum/",
  imgUrl: "https://insula-uat.oss-cn-chengdu.aliyuncs.com/",
  pubUrl: "https://forum-pub-uat.oss-cn-chengdu.aliyuncs.com/",
};

const proUrl = {
  ndUrl: "https://naodao.com/",
  baseURL: "https://forum.naodao.com/forum/", // 正式
  winURL: "https://forum.naodao.com", // 正式
  imgUrl: "https://insula.oss-cn-chengdu.aliyuncs.com/", // 正式
  pubUrl: "https://forum-pub.oss-cn-chengdu.aliyuncs.com/", // 正式
};

let mergeUrl;
if (process.env.VUE_APP_CURRENT_MODE === "production") {
  mergeUrl = Object.assign(commonUrl, proUrl);
}
if (process.env.VUE_APP_CURRENT_MODE === "pre-release") {
  mergeUrl = Object.assign(commonUrl, preReleaseUrl);
}
if (process.env.VUE_APP_CURRENT_MODE === "test") {
  mergeUrl = Object.assign(commonUrl, testUrl);
}
module.exports = mergeUrl;
